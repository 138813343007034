<template>
  <div style="margin-top:20px">
    <div class="search_flex" v-if="pageName !== 'crm'">
      <div class="search_left">
        <salesArea
          :hval="salesArea"
          @change="salesAreaChange"
          @saleArea="saleArea"
        />
        <inputComp
          :hval="customerName"
          @blur="customerNameBlur"
          placeholder="客户名称"
        />
        <inputComp :hval="code" @blur="codeBlur" placeholder="工单编号" />
        <inputComp
          :hval="orderCode"
          @blur="orderCodeBlur"
          placeholder="订单编号"
        />
        <inputComp
          :hval="placeContacter"
          @blur="placeContacterBlur"
          placeholder="下单人姓名"
        />
        <inputComp
          :hval="placeContacterMobile"
          @blur="placeContacterMobileBlur"
          placeholder="下单人手机号"
        />
        <inputComp
          :hval="repairContacter"
          @blur="repairContacterBlur"
          placeholder="报修人"
        />
        <inputComp
          :hval="contacterMobile"
          @blur="contacterMobileBlur"
          placeholder="报修人手机号"
        />
        <selectComp
          :hval="premiumProcessing"
          :data="premiumProcessingList"
          @change="premiumProcessingChange"
          placeholder="是否紧急"
        />
        <selectComp
          :hval="poorUnpacking"
          :data="poorUnpackingList"
          @change="poorUnpackingChange"
          placeholder="是否开箱不良"
        />
        <selectComp
          :hval="complaint"
          :data="complaintList"
          @change="complaintChange"
          placeholder="是否投诉"
        />
        <selectComp
          :hval="door"
          :data="doorList"
          @change="doorChange"
          placeholder="是否上门"
        />
        <dateComp :hval="date" @change="dateChange" />
        <btnGroup @search="search" @reset="reset" />
      </div>
    </div>
    <tableComp
      :data="tableData"
      :col-configs="theadName"
      :total="total"
      :currentPage="curPage"
      :pageSize="pageSize"
      v-loading="tableLoading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column slot="code" label="工单编号" min-width="200">
        <template v-slot="scope">
          <div style="color:#385BFF;cursor:pointer" @click="codeClick(scope.row)">{{scope.row.code}}
            <div style="color:#FF7F00">{{scope.row.remark}}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        slot="premiumProcessing"
        label="紧急/投诉/开箱不良"
        width="150"
      >
        <template v-slot="scope">
          <span
            >{{ scope.row.premiumProcessing ? '急' : '-' }} /
            {{ scope.row.complaint ? '诉' : '-' }} /
            {{ scope.row.poorUnpacking ? '不良' : '-' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column slot="handlerInfo" label="处理人" width="140">
        <template v-slot="scope">
          <ul>
            <li v-if="scope.row.serviceName">
              客服:{{ scope.row.serviceName }}
            </li>
            <li v-if="scope.row.remoteName">
              售后:{{
                scope.row.remoteName +
                  (scope.row.remoteNum > 1 ? '/' + scope.row.remoteNum : '') ||
                  '-'
              }}
            </li>
            <li v-if="scope.row.storeHandleName">
              商家:{{
                scope.row.storeHandleName +
                  (scope.row.storeNum > 1 ? '/' + scope.row.storeNum : '') ||
                  '-'
              }}
            </li>
            <li v-if="scope.row.dispatchName">
              调度:{{ scope.row.dispatchName }}
            </li>
            <li v-if="scope.row.visitNum">上门:{{ scope.row.visitNum }}</li>
          </ul>
        </template>
      </el-table-column>
      <el-table-column slot="expressInfo" label="用户已收/待发(快递)" width="120">
        <template v-slot="scope">
          <span>{{
            scope.row.finishConsignment + '/' + scope.row.notConsignment
          }}</span>
        </template>
      </el-table-column>
      <el-table-column slot="appointmentTime" label="工单时效" width="220">
        <template v-slot="scope">
          <div>{{ scope.row.appointmentTime }}</div>
          <div>
            <span
              :style="{
                color: scope.row.responsePrescription ? '' : '#385BFF'
              }"
              >{{
                scope.row.responsePrescription ? '已响应:' : '待响应:'
              }}</span
            >
            <span
              :style="{
                color: scope.row.responseDuration <= 30 * 60 ? '' : 'red'
              }"
              >{{ scope.row.responseDurationStr }}</span
            >
          </div>
          <div>
            <span
              :style="{ color: scope.row.handlePrescription ? '' : '#385BFF' }"
              >{{ scope.row.handlePrescription ? '已处理:' : '待处理:' }}</span
            >
            <span
              :style="{
                color: scope.row.handleDuration <= 4 * 60 * 60 ? '' : 'red'
              }"
              >{{ scope.row.handleDurationStr }}</span
            >
          </div>
        </template>
      </el-table-column>
    </tableComp>
    <!-- 详情弹窗 -->
    <drawerDetail @close="close" />
  </div>
</template>
<script>
import drawerDetail from '@/views/work-order/all-order/detail'
import salesArea from '@/views/components/salesArea'
import { saleQueryOrderList } from '@/api'
import { mapMutations } from 'vuex'
export default {
  props: {
    tabType: {
      type: String,
      default: 'follow'
    },
    pageName: {
      type: String,
      default: ''
    }
  },
  components: {
    drawerDetail,
    salesArea
  },
  data () {
    return {
      tableLoading: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      customerName: '',
      code: '',
      orderCode: '',
      placeContacter: '',
      placeContacterMobile: '',
      repairContacter: '',
      contacterMobile: '',
      poorUnpacking: null,
      premiumProcessing: null,
      complaint: null,
      door: null,
      startTime: '',
      endTime: '',
      date: [],
      level: '',
      cityId: '',
      salesArea: [],
      premiumProcessingList: [
        { label: '正常', value: 0 },
        { label: '紧急', value: 1 }
      ],
      poorUnpackingList: [
        { label: '正常', value: false },
        { label: '不良', value: true }
      ],
      complaintList: [
        { label: '否', value: false },
        { label: '是', value: true }
      ],
      doorList: [
        { label: '否', value: false },
        { label: '是', value: true }
      ]
    }
  },
  computed: {
    theadName () {
      return this.pageName === 'crm' ? [
        { slot: 'handlerInfo' },
        { prop: 'statusName', label: '工单状态', minWidth: 100 },
        { slot: 'premiumProcessing' },
        { slot: 'code' },
        { prop: 'customerName', label: '客户名称' },
        { prop: 'repairContacter', label: '报修人', minWidth: 100 },
        { prop: 'createdTime', label: '建单时间', minWidth: 120 }
      ] : [
        { slot: 'expressInfo' },
        { prop: 'statusName', label: '工单状态', width: 100 },
        { slot: 'premiumProcessing' },
        { slot: 'code' },
        { prop: 'customerName', label: '客户名称' },
        { prop: 'repairContacter', label: '报修人', width: 100 },
        { slot: 'appointmentTime' },
        { slot: 'handlerInfo' }
      ]
    }
  },
  mounted () {
    if (this.pageName === 'crm') {
      this.getList()
    }
  },
  methods: {
    ...mapMutations(['SET_DRAWER_SHOW', 'SET_INDEX_INFO']),
    // 子组件传过来战区信息
    saleArea (salesArea, level, cityId) {
      this.initSalesArea = salesArea
      this.initLevel = level
      this.initCityId = cityId
      this.level = level
      this.cityId = cityId
      this.salesArea = salesArea
      this.getList()
    },
    getList () {
      const params = {
        type: this.tabType === 'follow' ? 1 : 2, // 1：跟进工单，2：完结工单
        curPage: this.curPage,
        pageSize: this.pageSize,
        customerName: this.customerName,
        code: this.code,
        orderCode: this.orderCode,
        placeContacter: this.placeContacter,
        placeContacterMobile: this.placeContacterMobile,
        repairContacter: this.repairContacter,
        contacterMobile: this.contacterMobile,
        poorUnpacking: this.poorUnpacking,
        premiumProcessing: this.premiumProcessing,
        complaint: this.complaint,
        door: this.door,
        startTime: this.startTime,
        endTime: this.endTime,
        level: this.level,
        cityId: this.cityId
      }
      // crm页面（售前查单）
      let paramsToCrm = {}
      if (this.pageName === 'crm') {
        const { ids, code } = this.$route.query
        paramsToCrm = {
          curPage: this.curPage,
          pageSize: this.pageSize,
          code,
          saleids: ids === '-1' ? null : ids.split(',')
        }
      }
      // 区分是哪个页面传不同的参数
      const paramsNew = this.pageName === 'crm' ? paramsToCrm : params
      this.tableLoading = true
      saleQueryOrderList(paramsNew).then(res => {
        this.tableLoading = false
        this.tableData = res.data.list
        this.total = res.data.totalCount
      })
    },
    // 点击工单号弹窗
    codeClick (info) {
      this.info = info
      const { id, categoryId, orderCode } = info
      this.SET_DRAWER_SHOW(true)
      this.SET_INDEX_INFO({ id, categoryId, orderCode })
    },
    customerNameBlur (val) {
      this.customerName = val
    },
    codeBlur (val) {
      this.code = val
    },
    orderCodeBlur (val) {
      this.orderCode = val
    },
    placeContacterBlur (val) {
      this.placeContacter = val
    },
    placeContacterMobileBlur (val) {
      this.placeContacterMobile = val
    },
    repairContacterBlur (val) {
      this.repairContacter = val
    },
    contacterMobileBlur (val) {
      this.contacterMobile = val
    },
    premiumProcessingChange (val) {
      this.premiumProcessing = val
    },
    poorUnpackingChange (val) {
      this.poorUnpacking = val
    },
    complaintChange (val) {
      this.complaint = val
    },
    doorChange (val) {
      this.door = val
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    close () {
      this.SET_DRAWER_SHOW(false)
    },
    salesAreaChange (salesArea, level, cityId) {
      this.salesArea = salesArea
      this.level = level
      this.cityId = cityId
    },
    search () {
      this.curPage = 1
      this.getList()
      // // 限制只有这些条件才能查询
      // if (
      //   this.customerName ||
      //   this.code ||
      //   this.orderCode ||
      //   this.placeContacter ||
      //   this.placeContacterMobile ||
      //   this.repairContacter ||
      //   this.contacterMobile
      // ) {
      //   this.getList()
      // } else {
      //   this.$message.warning('已限制只有前7个搜索条件中任意1个可以搜索')
      // }
    },
    reset () {
      this.curPage = 1
      this.customerName = ''
      this.code = ''
      this.orderCode = ''
      this.placeContacter = ''
      this.placeContacterMobile = ''
      this.repairContacter = ''
      this.contacterMobile = ''
      this.startTime = ''
      this.endTime = ''
      this.poorUnpacking = null
      this.premiumProcessing = null
      this.complaint = null
      this.door = null
      this.date = []
      // 重置后赋值初始值
      this.level = this.initLevel
      this.cityId = this.initCityId
      this.salesArea = this.initSalesArea
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
