<template>
  <div class="layout_common scene_handle">
    <div class="main_box">
      <div class="main_box_con" style="padding-top:10px">
        <el-tabs class="tabs" v-model="activeName" style="padding-left:0">
          <el-tab-pane label="跟进工单" name="follow">
            <comp :tabType="activeName" v-if="activeName === 'follow'" />
          </el-tab-pane>
          <el-tab-pane label="完结工单" name="finish">
            <comp :tabType="activeName" v-if="activeName === 'finish'" />
          </el-tab-pane>
          <el-tab-pane label="查询工单状态" name="status">
            <searchStatus />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import comp from './comp'
import searchStatus from './search-status'
export default {
  components: {
    comp,
    searchStatus
  },
  data () {
    return {
      activeName: 'follow'
    }
  }
}
</script>
