<template>
  <el-drawer
    :visible.sync="drawerShow"
    :size="size"
    direction="rtl"
    :append-to-body="appendToBody"
    :before-close="handleClose"
  >
    <div slot="title">
      <div class="title">
        <img class="img" src="~@/assets/images/icon.png" alt="" />
        <div class="code">{{info.code}}</div>
        <div class="title_con">
          {{ `${info.orderTypeChiName}(${info.statusName})` }}
        </div>
        <div
          class="status status_zc"
          v-if="info.premiumProcessing == 0"
          @click="statusToggle(1)"
        >
          正常 <i class="el-icon-sort"></i>
        </div>
        <div class="status status_jj" v-else @click="statusToggle(0)">
          紧急<i class="el-icon-sort"></i>
        </div>
      </div>
    </div>
    <Detail @getInfo="getInfo" ref="detailRef" />
  </el-drawer>
</template>
<script>
import Detail from './detailCon'
import { mapState } from 'vuex'
export default {
  name: 'drawerDetail',
  components: {
    Detail
  },
  props: {
    size: {
      type: String,
      default: '90%'
    },
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      info: {}
    }
  },
  computed: {
    ...mapState(['drawerShow'])
  },
  methods: {
    // 接收子组件的值
    getInfo (val) {
      this.info = val
    },
    // 修改紧急状态
    statusToggle (status) {
      this.$refs.detailRef.statusToggle(status)
    },
    handleClose () {
      this.drawerClose()
    },
    drawerClose () {
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
  .title {
    display: flex;
    align-items: center;
    color: #333;
    font-size: 18px;
    margin-bottom: 5px;
    .img {
      width: 28px;
      height: 28px;
      margin-right: 16px;
    }
    .code {
      font-size: 16px;
      margin-right: 10px;
    }
    .status {
      width: 64px;
      height: 22px;
      line-height: 22px;
      border-radius: 12px;
      font-size: 14px;
      text-align: center;
      margin-left: 8px;
      cursor: pointer;
      &.status_zc {
        background: rgba(14, 190, 123, 0.08);
        border: 1px solid #0ebe7b;
        color: #0ebe7b;
      }
      &.status_jj {
        background: rgba(253, 84, 84, 0.08);
        border: 1px solid #fd5454;
        color: #fd5454;
      }
    }
  }
</style>
