<template>
  <div class="search_status">
    <div class="input_search">
      <inputComp
        class="input"
        placeholder="请输入要查询的工单编号"
        :hval="orderCode"
        @blur="orderCodeBlur"
      />
      <btnGroup @search="search" @reset="reset" />
    </div>
    <h3 class="status_msg">
      该工单状态：<span :class="statusMsg === '删除' ? 'active' : ''">{{
        statusMsg || '-'
      }}</span>
    </h3>
  </div>
</template>
<script>
import { searchOrderStatus } from '@/api'
export default {
  data () {
    return {
      orderCode: '',
      statusMsg: ''
    }
  },
  methods: {
    async searchStatus () {
      const res = await searchOrderStatus({ orderCode: this.orderCode })
      this.statusMsg = res.data === '终结' ? '删除' : res.data
    },
    orderCodeBlur (val) {
      this.orderCode = val
    },
    search () {
      this.searchStatus()
    },
    reset () {
      this.orderCode = ''
      this.statusMsg = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.search_status {
  margin-top: 20px;
  .status_msg {
    padding-left: 20px;
    .active {
      color: red;
    }
  }
}
.input_search {
  display: flex;
  align-items: center;
  .input {
    width: 300px;
    margin: 20px;
  }
}
</style>
