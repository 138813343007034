<template>
   <el-cascader
    v-model="salesArea"
    :options="options"
    size="small"
    filterable
    :clearable="clearable"
    :disabled="isDisabled || disabled"
    :placeholder="placeholder"
    :props="{ expandTrigger: 'click',value:'id',label:'name',checkStrictly: true}"
    @change="handleChange"></el-cascader>
</template>

<script>
import { salesAreaData } from '@/api'
export default {
  props: {
    hval: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择战区'
    },
    // 标识是否是城市开箱不良的问题分析页面
    isCityUnpackAnaly: {
      type: Boolean,
      default: false
    },
    // 是否是详情页面（详情页面需要取地址栏query的参数）
    isDetailPage: {
      type: Boolean,
      default: false
    },
    // 是否需要按照登录角色来处理
    isLoginRole: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      salesArea: [],
      level: '', // 1战区 2城市
      cityId: '',
      options: [],
      isDisabled: false, // 同时有战区和城市就禁选择
      clearable: true
    }
  },
  watch: {
    hval: {
      immediate: true,
      handler (val) {
        this.salesArea = val
      },
      deep: true
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      salesAreaData().then(res => {
        this.options = res.data
        this.isLoginRole && this.handleSaleArea()
      })
    },
    // 如果是销售登录的战区和城市要显示出来
    handleSaleArea () {
      const { salesAreaId, salesAreaChiId, businessRole } = this.$store.state.loginUserInfo
      const { level, cityId, salesArea } = this.$route.query
      if (businessRole !== 'sale') {
        if (this.isCityUnpackAnaly || this.isDetailPage) {
          this.level = level
          this.cityId = cityId
          this.salesArea = salesArea.split(',')
          return
        }
        this.level = ''
        this.cityId = ''
        this.salesArea = []
      } else {
        // 有战区且有有城市
        if (salesAreaId && salesAreaChiId) {
          // 地址栏有参数的优先选
          this.salesArea = salesArea ? salesArea.split(',') : [salesAreaId, salesAreaChiId]
          this.level = 2
          this.cityId = cityId || salesAreaChiId
          this.isDisabled = true // 禁止选择
        // 只有战区
        } else if (salesAreaId && !salesAreaChiId) {
          this.options = this.options.filter(item => item.id === salesAreaId)
          this.salesArea = salesArea ? salesArea.split(',') : [salesAreaId]
          this.level = 1
          this.cityId = cityId || salesAreaId
          this.clearable = false
        }
      }
      // 战区信息传给父组件
      this.$emit('saleArea', this.salesArea, this.level, this.cityId)
    },
    handleChange (val) {
      this.salesArea = val
      if (val.length === 1) {
        this.level = 1
        this.cityId = val[0]
      } else if (val.length === 2) {
        this.level = 2
        this.cityId = val[1]
      } else {
        this.level = ''
        this.cityId = ''
      }
      this.$emit('change', this.salesArea, this.level, this.cityId)
    }
  }
}
</script>
